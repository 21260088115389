import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import ProductBlock from '../../components/ProductBlock';

const Container = styled.div`
  margin: 60px 0px;
  padding: 20px 0px;
  position: relative;
  min-height: 400px;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;

  ${({ theme }) => theme.media.tablet`
    margin: 30px 0px 0px;
  `}
`;

const BackgroundColor = styled.div`
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%;
  height: 70%;
  z-index: -1;
  background-color: rgba(${({ theme }) => theme.colors.mainBlue.rgb}, 0.15);


  ${({ theme }) => theme.media.tablet`
    top: 0;
    height: 100%;
  `}
`;

const Dalle = () => (
    <Container>
        <BackgroundColor />
        <ProductBlock keyProduct="1"
            imgSrc="/images/dalle.png"
            noButton />
    </Container>
);

export default React.memo(withTranslation()(Dalle));
