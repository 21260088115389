import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import Layout from '../../components/Layout';
import {
    PageTitle, MainText
} from '../../components/Text';

import Steatite from '../../components/products/Steatite';
import Dalle from '../../components/products/Dalle';
import Information from '../../components/products/Information';

const MainTextStyled = styled(MainText)`
  width: 60%;
  margin: 0 auto;
  text-align: center;
  max-width: 600px;

  ${({ theme }) => theme.media.tablet`
    width: auto;
  `}
`;

// markup
const IndexPage = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <Helmet>
                <title>
                    {t('products_meta_title')}
                </title>
                <meta name="description"
                    content={t('products_meta_description')} />
            </Helmet>
            <PageTitle>
                {t('products')}
            </PageTitle>
            <MainTextStyled>
                {t('products_description')}
            </MainTextStyled>
            <Steatite />
            <Dalle />
            <Information />
        </Layout>
    );
};

export default IndexPage;
