import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
    BlockTitle, MainText
} from './../Text';
import { MainContainer } from './../Container';

const BlockRight = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.tablet`
    width: auto;
    margin-top: 20px;
  `}
`;

const Image = styled.img`
    width: 100%;
`;

const Row = styled.div`
  display: flex;

  ${({ theme }) => theme.media.mobile`
    flex-direction: column;
  `}
`;

const Table = styled.table`
    margin-left: 20px;
`;

const TRow = styled.tr`
    &:last-child td:after {
        content: none;
    }
`;

const TCell = styled.td`
    padding: 10px;
    border: none;
    position: relative;
    
    &:first-child&:before {
        content: "";
        position: absolute;
        background-color: ${({ theme }) => theme.colors.mainBlue.hex};
        height: calc(100% + 5px);
        width: 2px;
        right: 0;
        top: 0;
    }

    &:after {
        content: "";
        position: absolute;
        background-color: ${({ theme }) => theme.colors.mainBlue.hex};
        width: calc(100% + 5px);
        height: 2px;
        right: 0;
        bottom: 0;
    }
`;

const Block = styled.div`
    width: 50%;

    ${({ marginLeft }) => marginLeft ? 'margin-left: 20px;' : ''}
    ${({ marginRight }) => marginRight ? 'margin-right: 20px;' : ''}

    ${({ theme }) => theme.media.tablet`
      width: auto;
      margin-left: 0px;
      margin-right: 0px;
    `}
`;

const Steatite = () => {
    const { t } = useTranslation();

    return (
        <MainContainer>
            <BlockTitle>
                {t('product_title_2')}
            </BlockTitle>
            <Row>
                <Block marginRight>
                    <MainText dangerouslySetInnerHTML={{ __html: t('product_description_2') }} />
                    <BlockTitle>
                        {t('our_range')}
                    </BlockTitle>
                    <Table>
                        <tbody>
                            <TRow>
                                <TCell>Ø34</TCell>
                                <TCell>{t('compatible_from_to', {
                                    from: 'Ø32',
                                    to: 'Ø34'
                                })}</TCell>
                            </TRow>
                            <TRow>
                                <TCell>Ø39</TCell>
                                <TCell>{t('compatible_from_to', {
                                    from: 'Ø37',
                                    to: 'Ø39'
                                })}</TCell>
                            </TRow>
                            <TRow>
                                <TCell>Ø46</TCell>
                                <TCell>{t('compatible_from_to', {
                                    from: 'Ø45',
                                    to: 'Ø46'
                                })}</TCell>
                            </TRow>
                            <TRow>
                                <TCell>Ø48</TCell>
                                <TCell>{t('compatible_from_to', {
                                    from: 'Ø46',
                                    to: 'Ø48'
                                })}</TCell>
                            </TRow>
                            <TRow>
                                <TCell>Ø51</TCell>
                                <TCell>{t('compatible_from_to', {
                                    from: 'Ø50',
                                    to: 'Ø52'
                                })}</TCell>
                            </TRow>
                            <TRow>
                                <TCell>Ø53</TCell>
                                <TCell>{t('compatible_from_to', {
                                    from: 'Ø53',
                                    to: 'Ø54'
                                })}</TCell>
                            </TRow>
                        </tbody>
                    </Table>
                    <MainText>
                        {t('product_description_2_1')}
                    </MainText>
                </Block>
                <BlockRight>
                    <Image
                        src="/images/resistance.png"
                        alt={t('product_title_2')}
                    />
                    <MainText>
                        {t('product_description_2_2')}
                    </MainText>
                </BlockRight>
            </Row>
        </MainContainer>
    );
};

export default React.memo(Steatite);
